<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>

                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Registro cotización de certificación</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">

                                    <b-tabs v-model="tabIndex" variant="pills">
                                        <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(datosGenerales)">
                                                <b-tab>
                                                    <template slot="title">
                                                        <i class="fas fa-server fa-md"></i> Datos generales
                                                    </template>
                                                    <b-row class="mt-3">
                                                        <!--LISTA LOS CLIENTES-->

                                                        <b-col md="12">
                                                            <!-- {{datosCotizacion.idSubCliente}} -->
                                                            <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Cliente:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" :reduce="listaClientes => listaClientes.idSubCliente" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.idSubCliente , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.idSubCliente" :options="listaClientes">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Normas:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" @input="checkNormas" multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.normas" :options="listaNormas">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="auditoría" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Auditorías:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaIntegracion => listaIntegracion.idIntegracion" label="nombre" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.integraciones.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.integraciones" :options="listaIntegracion">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- {{datosCotizacion.tipoCertificaciones}} -->
                                                        <b-col md="6">
                                                            <validation-provider name="tipo de certificación solicitada" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Tipo de certificación solicitada:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" @input="handleInput" :reduce="listaTipoCertificacionSolicitada => listaTipoCertificacionSolicitada.idTipoCertificacionSolicitada" label="nombre" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.tipoCertificaciones.length > 0 , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.tipoCertificaciones" :options="listaTipoCertificacionSolicitada">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        
                                                        <b-col md="6">
                                                            <validation-provider name="tipo de auditoría solicitada" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Tipo de auditoría solicitada:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaTipoAuditorias => listaTipoAuditorias.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.tipoAuditorias.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.tipoAuditorias" :options="listaTipoAuditorias">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="se requiere acreditación" v-slot="{errors}">
                                                                <b-form-group label="Se requiere acreditación:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaAcreditaciones => listaAcreditaciones.idAcreditacion" label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosCotizacion.acreditacionesRequeridad.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.acreditacionesRequeridad" :options="listaAcreditaciones">
                                                                        <span slot="no-options" class="text-muted">Debe seleccionar al menos una norma</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- <b-col md="6">
                                                             !-- CREANDO UN SE v ECT PARA EL TIPO DE ACREDITACIÓN -->
                                                        <!-- <validation-provider name="tipo de acreditación" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Tipo de acreditación solicitada:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaAcreditaciones => listaAcreditaciones.idAcreditacion" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.tipoAcreditacion.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.tipoAcreditacion" :options="listaAcreditacioneses">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col> --> 
                                                    </b-row>
                                                    <!-- <b-row v-if="!!datosCotizacion.tipoCertificaciones.find(x => x == 3)"> -->
                                                    <b-row v-if="datosCotizacion.tipoCertificaciones == 3">
                                                        <b-col md="6">
                                                            <validation-provider name="nombre del organismo" :rules="{ required: true }" v-slot="validationContext">
                                                                <b-form-group label="Nombre del organismo:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del organismo" v-model.lazy="datosCotizacion.nombreOrganismo"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="cuerpo de acreditación" v-slot="validationContext">
                                                                <b-form-group label="Cuerpo de acreditación:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el cuerpo de acreditación" v-model.lazy="datosCotizacion.cuerpoAcreditacion"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider name="número certificado" v-slot="validationContext">
                                                                <b-form-group label="Número certificado:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el número del certificado" v-model.lazy="datosCotizacion.numeroCertificado"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider name="fecha de vencimiento" :rules="{ required: true }" v-slot="validationContext">
                                                                <b-form-group label="Fecha de vencimiento:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="date" v-model.lazy="datosCotizacion.fechaVencimiento"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider name="tipo de última auditoría realizada" v-slot="validationContext">
                                                                <b-form-group label="Tipo de última auditoría realizada:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el tipo última auditoría realizada" v-model.lazy="datosCotizacion.tipoUltimaAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col md="12">
                                                            <validation-provider name="alcance" v-slot="validationContext">
                                                                <b-form-group label="Alcance (El alcance de la certificación se revisará durante la evaluación y puede ser revisado, si es necesario para mayor claridad y el alcance de la certificación se indicará para cada ubicación en el certificado de cumplimiento, según corresponda):" class="mb-2">
                                                                    <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="2" max-rows="6" placeholder="Ingrese el alcance" v-model.lazy="datosCotizacion.alcanceCertificacion"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button :to="{name: 'Cotizaciones certificacion'}" class="mr-2" variant="dark" type="button">
                                                                <i class="fas fa-arrow-left"></i> Volver
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion1)">
                                                <b-tab :title-item-class="datosCotizacion.idCotizacionCertificacion=='' ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog fa-md"></i> Ubicación y disposición
                                                    </template>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col md="6">
                                                            <validation-provider name="ubicación (es)" v-slot="{errors}">
                                                                <b-form-group label="Ubicación (es) que se cubrirán en el ámbito de la certificación:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaUbicaciones => listaUbicaciones.idUbicacion" label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosCotizacion.ubicacionesAmbito.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.ubicacionesAmbito" :options="listaUbicaciones">
                                                                        <span slot="no-options" class="text-muted">Debe seleccionar al menos una norma</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3" v-if="disabledNorma4 && !!datosCotizacion.ubicacionesAmbito.find(x => x == 4)">
                                                            <validation-provider name="número de sucursales" v-slot="validationContext">
                                                                <b-form-group label="Número de sucursales:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el número de sucursales" v-model.lazy="datosCotizacion.numeroSucursales"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3" v-if="disabledNorma4 && !!datosCotizacion.ubicacionesAmbito.find(x => x == 4)">
                                                            <validation-provider name="mano de obra en cada sucursal" v-slot="validationContext">
                                                                <b-form-group label="Mano de obra en cada sucursal:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el número de mano de obra" v-model.lazy="datosCotizacion.manoObraSucursal"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3">
                                                            <validation-provider name="total de empleados" :rules="{ required: true }" v-slot="validationContext">
                                                                <b-form-group label="Total de empleados:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad de empleados" v-model.lazy="datosCotizacion.totalEmpleados"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3">
                                                            <validation-provider name="tiempo completo" v-slot="validationContext">
                                                                <b-form-group label="Tiempo completo:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad de empleados" v-model.lazy="datosCotizacion.totalEmpleadosCompleto"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3">
                                                            <validation-provider name="tiempo parcial" v-slot="validationContext">
                                                                <b-form-group label="Tiempo parcial:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad de empleados" v-model.lazy="datosCotizacion.totalEmpleadosParcial"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3">
                                                            <validation-provider name="estacional" v-slot="validationContext">
                                                                <b-form-group label="Estacional:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad de empleados" v-model.lazy="datosCotizacion.totalEmpleadosEstacional"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3">
                                                            <validation-provider name="por proyecto" v-slot="validationContext">
                                                                <b-form-group label="Por proyecto:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad de empleados" v-model.lazy="datosCotizacion.totalEmpleadosProyecto"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="3">
                                                            <validation-provider name="fecha de implementación del Sistema" :rules="{ required: true }" v-slot="validationContext">
                                                                <!-- <b-form-group :label="`Fecha de implementación de ${disabledNorma1 ? 'SGC' : disabledNorma2 ? 'SGA' : disabledNorma3 ? 'OHSMS' : disabledNorma4 ? 'SGAS -ABMS' : disabledNorma5 ? 'SMS SGSI' : disabledNorma6 ? 'SMS SGSI' : disabledNorma5  && disabledNorma6 ? 'SMS SGSI' :  disabledNorma1  && disabledNorma2 && disabledNorma3 ? 'IMS' : 'Sistema de Gestión'}:`" class="mb-2"> -->
                                                                <b-form-group label="Fecha de implementación del Sistema:" class="mb-2">
                                                                    <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="date" v-model.lazy="datosCotizacion.fechaImplementacionIMS"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- <b-col md="6">
                                                            <validation-provider name="se requiere acreditación" v-slot="{errors}">
                                                                <b-form-group label="Se requiere acreditación:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaAcreditaciones => listaAcreditaciones.idAcreditacion" label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosCotizacion.acreditacionesRequeridad.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.acreditacionesRequeridad" :options="listaAcreditaciones">
                                                                        <span slot="no-options" class="text-muted">Debe seleccionar al menos una norma</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col> -->
                                                        <b-col md="6" v-if="datosCotizacion.disposicionAuditoriaRemota == 1">
                                                            <validation-provider name="metodología de auditoría" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Metodología de auditoría:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaMetodologiasAuditoria => listaMetodologiasAuditoria.idMetodologiaAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.metodologiasAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.metodologiasAuditoria" :options="listaMetodologiasAuditoria">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <b-form-group label="Disposición del cliente para la auditoría remota y organizar la infraestructura solicitada:" class="mb-2">
                                                                <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.disposicionAuditoriaRemota" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                            </b-form-group>
                                                        </b-col>

                                                        <b-col md="6" v-if="datosCotizacion.disposicionAuditoriaRemota == 1">
                                                            <validation-provider name="infraestrutura remota" rules="required" v-slot="{errors}">
                                                                <b-form-group label="La infraestructura remota incluye:" class="mb-2">
                                                                    <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaInfraestructurasRemota => listaInfraestructurasRemota.idInfraestructurasRemota" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.infraestructurasAuditoriaRemota.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.infraestructurasAuditoriaRemota" :options="listaInfraestructurasRemota">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" v-if="datosCotizacion.disposicionAuditoriaRemota == 1">
                                                            <validation-provider name="detalles de auditoría remota" v-slot="validationContext">
                                                                <b-form-group label="Detalles de auditoría remota:" class="mb-2">
                                                                    <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle de la auditoría remota" v-model.lazy="datosCotizacion.detallesAuditoriaRemota"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <validation-provider name="representante local" v-slot="validationContext">
                                                                <b-form-group label="Representante local:" class="mb-2">
                                                                    <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el representante y dirección de la Oficina de enlace local (para comunicación)" v-model.lazy="datosCotizacion.representanteLocal"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <span class="text-muted">Si la auditoría remota se acuerda como completa o parcial, IQC enviará un enlace para la videoconferencia por WebEx o Zoom un día antes de la auditoría.</span>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion2)">
                                                <b-tab :title-item-class="datosCotizacion.idCotizacionCertificacion=='' ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog fa-md"></i> Detalle de la empresa
                                                    </template>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12">
                                                                <b-table-simple bordered hover show-empty mediun responsive outlined>
                                                                    <b-thead>
                                                                        <b-tr>
                                                                            <b-th colspan="2">Indique la dirección de la ubicación como debería aparecer en el certificado de cumplimiento y los procesos en cada ubicación, incluido la Oficina registrada, ya que el alcance de la certificación se detallará para cada ubicación en el certificado de cumplimiento, según corresponda</b-th>
                                                                            <b-th>Proceso (s) del sistema de gestión en la ubicación</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="30%">Oficina registrada:</b-td>
                                                                            <b-td width="35%">
                                                                                <validation-provider name="a "  v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[0].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td width="35%">
                                                                                <validation-provider name="s " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[0].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="(!disabledNorma5 || !disabledNorma6) && (disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4)">
                                                                            <b-td>Planta: (Si es diferente de la ubicación de la Oficina registrada </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="d " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[1].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="f " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[1].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>Oficinas regionales y número de oficinas </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="g " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[2].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="h " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[2].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>Sucursales Oficina (s) y número de sucursales: (Adjunte una hoja separada, si es necesario)</b-td>
                                                                            <b-td>
                                                                                <validation-provider name="j " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[3].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="k " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[3].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma5 || disabledNorma6">
                                                                            <b-td>Centros de desarrollo</b-td>
                                                                            <b-td>
                                                                                <validation-provider name="l " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[4].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="q " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[4].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma5 || disabledNorma6">
                                                                            <b-td>Centros de apoyo</b-td>
                                                                            <b-td>
                                                                                <validation-provider name="w " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[5].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="e " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[5].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma5 || disabledNorma6">
                                                                            <b-td>Centros de desarrollo Offshore (ODEC, si corresponde)</b-td>
                                                                            <b-td>
                                                                                <validation-provider name="r " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[6].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="t " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[6].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma5 || disabledNorma6">
                                                                            <b-td>Sitio de recuperación ante desastres</b-td>
                                                                            <b-td>
                                                                                <validation-provider name="y " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[7].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="u " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[7].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma5 || disabledNorma6">
                                                                            <b-td>Otros </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="i " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[8].descripcion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <validation-provider name="o " v-slot="validationContext">
                                                                                    <b-form-group label="" class="mb-2">
                                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleEmpresa[8].proceso" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                                    </b-form-group>
                                                                                </validation-provider>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>

                                                            <b-col md="12" class="text-right mb-1">
                                                                <CButton v-if="!$route.params.id" @click="agregarFilaSurcursal()" size="sm" color="dark">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar documento
                                                                </CButton>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table :items="listaDocumentoEmpresaSucursal.filter(x => x.estado=='2')" :fields="camposDetalleEmpresa" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                    <template v-slot:cell(documento)="row">
                                                                        <validation-provider :name="'documento detalle empresa-'+row.index" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-input-group>
                                                                                    <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                                                            <i class="fas fa-download fa-xs"></i>
                                                                                        </b-button>
                                                                                    </b-input-group-prepend>
                                                                                    <b-form-file v-if="!$route.params.id" ref="file" class="text-left" v-model.lazy="row.item.documento" v-on:change="handleFileUpload(row.index, $event)" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                                                                    <b-form-input v-if="$route.params.id" disabled class="disabled-white" :state="getValidationState(validationContext)" v-model.lazy="row.item.nombreDocumento"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-input-group>
                                                                            </b-form-group>
                                                                        </validation-provider>

                                                                    </template>
                                                                    <template #cell(opciones)="param">
                                                                        <b-button @click="quitarFilaSucursal(param)" v-if="!$route.params.id && listaDocumentoEmpresaSucursal.filter(x => x.estado=='2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                        </b-button>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="número total de empleados" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Número total de empleados:" class="mb-2">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="number" placeholder="Ingrese el número total de empleados" v-model.lazy="datosCotizacion.numeroTotalEmpleados"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="9" class="mt-2">
                                                                <span>Cantidad de personal:</span>
                                                            </b-col>
                                                            <b-col md="3" class="text-right mb-1">
                                                                <CButton v-if="!$route.params.id" @click="modalAgregarPersonal = true" size="sm" color="dark">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar personal
                                                                </CButton>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table :items="datosCotizacion.listaPersonal" class="table-custom" :fields="campoPersonal" bordered show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                    <template #cell(opciones)="param">
                                                                        <!-- <b-button @click="abrirModalActualizarEmpleado(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                                        </b-button> -->
                                                                        <b-button v-if="!$route.params.id" @click="eliminarPersonal(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                        </b-button>
                                                                    </template>
                                                                    <template #cell(tipo)="param">
                                                                        <span v-if="param.item.tipo==1">Alta dirección</span>
                                                                        <span v-else-if="param.item.tipo==2">Gerentes, ingenieros, supervisores y otros</span>
                                                                        <span v-else-if="param.item.tipo==3">Técnicos comerciales</span>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="mano de obra total alta dirección" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Mano de obra total (Alta dirección, gerentes y otros):" class="mb-2">
                                                                        <b-form-input disabled class="disabled-white" :state="getValidationState(validationContext)" type="number" placeholder="Mano de obra total" v-model.lazy="datosCotizacion.manoObraTotal1"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="mano de obra total técnivos comerciales" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Mano de obra total (Técnicos comerciales):" class="mb-2">
                                                                        <b-form-input disabled class="disabled-white" :state="getValidationState(validationContext)" type="number" placeholder="Mano de obra total" v-model.lazy="datosCotizacion.manoObraTotal2"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="proyecto de mano de obra" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Proyecto de mano de obra:" class="mb-2">
                                                                        <b-form-input disabled class="disabled-white" :state="getValidationState(validationContext)" type="number" placeholder="Proyecto de mano de obra" v-model.lazy="datosCotizacion.proyectoManoObra"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="¿está documentado el organigrama?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Está documentado el organigrama?">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-1" v-model.lazy="datosCotizacion.hasDocumentadoOrganigrama" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4" v-if="datosCotizacion.hasDocumentadoOrganigrama=='1'">
                                                                <validation-provider name="documento" v-slot="validationContext">
                                                                    <b-form-group label="Documento:">
                                                                        <b-input-group class="disabled-white">
                                                                            <b-input-group-prepend v-if="datosCotizacion.nombreDocumentoOrganigrama != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosCotizacion.urlDocumentoOrganigrama)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-if="!$route.params.id" ref="fileOrg" class="text-left " v-model.lazy="datosCotizacion.documentoOrganigrma" v-on:change="handleFileUploadOrganigrama" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                                                            <b-form-input v-if="$route.params.id" disabled class="disabled-white" :state="getValidationState(validationContext)" v-model.lazy="datosCotizacion.nombreDocumentoOrganigrama"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="¿las operaciones se ralizan por turnos?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Las operaciones se ralizan por turnos?">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-2" v-model.lazy="datosCotizacion.operacionesPorTurnos" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4" v-if="datosCotizacion.operacionesPorTurnos=='1'">
                                                                <validation-provider name="número de turnos" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Número de turnos:">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="number" placeholder="Ingrese el número de turnos" v-model.lazy="datosCotizacion.numeroTurnos"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4" v-if="datosCotizacion.operacionesPorTurnos=='1'">
                                                                <validation-provider name="tiempo" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Tiempo:">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="number" placeholder="Ingrese el tiempo" v-model.lazy="datosCotizacion.tiempo"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="¿Los procesos de fabricación son iguales en todos los turnos?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Los procesos de fabricación son iguales en todos los turnos?">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-3" v-model.lazy="datosCotizacion.procesoFabricacion" :options="comboProcesoFabricacion" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4" v-if="datosCotizacion.procesoFabricacion=='2'">
                                                                <validation-provider name="detalles de la operación en cada turno" v-slot="validationContext">
                                                                    <b-form-group label="Detalles de la operación en cada turno:">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" rows="2" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosCotizacion.detalleOperacion"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="Idioma de comunicación" v-slot="validationContext">
                                                                    <b-form-group label="Idioma de comunicación, si no es inglés:">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese el idioma de comunicación" v-model.lazy="datosCotizacion.idiomaComunicacion"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="¿La organización utilizó el servicio de consultoría para el desarrollo de sistemas de gestión? " :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿La organización utilizó el servicio de consultoría para el desarrollo de sistemas de gestión? ">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-4" v-model.lazy="datosCotizacion.servicioConsultoria" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4" v-if="datosCotizacion.servicioConsultoria=='1'">
                                                                <validation-provider name="nombre del consultor u organización consultora:" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Nombre del consultor u organización consultora:">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese el nomnre de consultor u organización" v-model.lazy="datosCotizacion.nombreConsultor"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion3)">
                                                <b-tab :title-item-class="datosCotizacion.idCotizacionCertificacion=='' ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Configuración de normas
                                                    </template>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row v-if="disabledNorma5 || disabledNorma6">
                                                            <b-col md="12" class="mb-2">
                                                                <span class="text-muted h5"><u>SGC ISO 27001</u></span>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <validation-provider name="tipo de organización">
                                                                    <b-form-group label="Tipo de organización:">
                                                                        <b-form-checkbox-group :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" small plain v-model.lazy="datosCotizacion.listaTipoOrganizacion" :options="comboTipoOrganizacion"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="centro de datos">
                                                                    <b-form-group label="Centro de datos">
                                                                        <!-- <b-form-select v-model.lazy="datosCotizacion.centroDatos" :options="comboCentroDatos">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select> -->
                                                                        <b-form-checkbox-group :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" small plain v-model.lazy="datosCotizacion.centroDatos" :options="comboCentroDatos"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="servidores utilizados">
                                                                    <b-form-group label="Servidores utilizados">
                                                                        <b-form-checkbox-group :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" small plain v-model.lazy="datosCotizacion.servidores" :options="comboServidores"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="text-right mb-1">
                                                                <CButton v-if="!$route.params.id" @click="agregarFilaAplicacion()" size="sm" color="dark">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar aplicación
                                                                </CButton>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table :items="listaAplicaciones.filter(x => x.estado=='2')" :fields="camposAplicaciones" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                    <template v-slot:cell(aplicacion)="row">
                                                                        <validation-provider :name="'aplicación-'+row.index" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la aplicación" v-model.lazy="row.item.aplicacion"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>

                                                                    </template>
                                                                    <template #cell(opciones)="param">
                                                                        <b-button @click="quitarFilaAplicacion(param)" v-if="!$route.params.id && listaAplicaciones.filter(x => x.estado=='2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                        </b-button>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                            <b-col md="12" class="text-right mb-1">
                                                                <CButton v-if="!$route.params.id" @click="agregarFilaAplicacionSoporte()" size="sm" color="dark">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar aplicación de soporte
                                                                </CButton>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table :items="listaAplicacionesSoporte.filter(x => x.estado=='2')" :fields="camposAplicacionesSoporte" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                    <template v-slot:cell(aplicacion)="row">
                                                                        <validation-provider :name="'aplicación-'+row.index" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese la aplicación" v-model.lazy="row.item.aplicacion"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </template>
                                                                    <template v-slot:cell(numero)="row">
                                                                        <validation-provider :name="'número-'+row.index" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="number" placeholder="Ingrese el número" v-model.lazy="row.item.numero"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </template>
                                                                    <template #cell(opciones)="param">
                                                                        <b-button @click="quitarFilaAplicacionSoporte(param)" v-if="!$route.params.id && listaAplicacionesSoporte.filter(x => x.estado=='2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                        </b-button>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="facilidad de acceso remoto" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Facilidad de acceso remoto:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-dar" v-model.lazy="datosCotizacion.facilidadAccesoRemoto" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="detalles de la infraestructura de TI" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Detalles de la infraestructura de TI:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-diti" v-model.lazy="datosCotizacion.detalleInfraestructura" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="vista macro de herramientas internas" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Vista macro de herramientas internas:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-vmhi" v-model.lazy="datosCotizacion.vistaMacroHeramientas" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="protocolos" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Protocolos:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-p" v-model.lazy="datosCotizacion.protocolos" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="número de enrutadores" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Número de enrutadores:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-ne" v-model.lazy="datosCotizacion.numeroEnrutadores" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="mecanismo de control de acceso" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Mecanismo de control de acceso:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-mca" v-model.lazy="datosCotizacion.mecanismoControlAcceso" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="dispositivos informáticos personales" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Dispositivos informáticos personales:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-dip" v-model.lazy="datosCotizacion.dispositivosInformaticos" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="seguridad física" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Seguridad física:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-sf" v-model.lazy="datosCotizacion.seguridadFisica" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="centros de desarrollo offshore (ODEC)" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Mecanismo de control de acceso:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-odec" v-model.lazy="datosCotizacion.centrosDesarrollo" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="Sitio de recuperación ante desastres" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Sitio de recuperación ante desastres:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-srd" v-model.lazy="datosCotizacion.sitioRecuperacionDesastres" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <!-- <b-col md="12">
                                                                <hr>
                                                            </b-col> -->
                                                        </b-row>
                                                        <b-row v-if="(disabledNorma1 && disabledNorma2) || (disabledNorma1 && disabledNorma3) || (disabledNorma2 && disabledNorma3) || (disabledNorma1 && disabledNorma2 && disabledNorma3)">
                                                            <b-col md="12">
                                                                <b-form-group label="Indique el nivel de integración de los sistemas de gestión según los detalles a continuación:">
                                                                    <b-form-checkbox-group :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" v-model.lazy="datosCotizacion.nivelesIntegracion" :options="listaNivelIntegracion" stacked small plain value-field="idNivelIntegracion" text-field="descripcion"></b-form-checkbox-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row v-if="disabledNorma1">
                                                            <b-col md="12" class="mb-2">
                                                                <span class="text-muted h5"><u>SGC ISO 9001: 2015</u></span>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group label="¿Hay algún proceso que no sea aplicable al sistema de gestión de la calidad?">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isProcesoAplicable" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.isProcesoAplicable == 1">
                                                                <validation-provider name="indique el (los) proceso (s) y los números de cláusula aplicables" v-slot="validationContext">
                                                                    <b-form-group label="Indique el (los) proceso (s) y los números de cláusula aplicables:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle de la auditoría remota" v-model.lazy="datosCotizacion.detalleProcesoAplicable"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <validation-provider name="proporcione una justificación de la no aplicabilidad" v-slot="validationContext">
                                                                    <b-form-group label="Proporcione una justificación de la no aplicabilidad de cualquier proceso: (se revisará durante la auditoría de la Etapa I para su aceptación o no):" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle de la auditoría remota" v-model.lazy="datosCotizacion.detalleProcesoNoAplicable"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group label="¿Existe algún producto que no esté incluido en el alcance de la certificación?">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isProductoNoIncluido" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.isProductoNoIncluido == 1">
                                                                <validation-provider name="indique los detalles del producto" v-slot="validationContext">
                                                                    <b-form-group label="Indique los detalles del producto:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle de la auditoría remota" v-model.lazy="datosCotizacion.detalleProductoNoIncluido"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row v-if="disabledNorma2">
                                                            <b-col md="12" class="mb-2">
                                                                <span class="text-muted h5"><u>ISO 14001-2015 EMS</u></span>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-form-group label="¿El aspecto y el impacto cubren alguno de los siguientes controles y están definidos?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isImpactoCubren" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>

                                                            <b-col md="12" v-if="datosCotizacion.isImpactoCubren==1">
                                                                <validation-provider name="controles" v-slot="{errors}">
                                                                    <b-form-group label="Controles:" class="mb-2">
                                                                        <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaImpactoControles => listaImpactoControles.idImpactoControles" label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosCotizacion.detallesImpactoCubren.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.detallesImpactoCubren" :options="listaImpactoControles">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                                <!-- <b-form-group label="Indique el nivel de integración de los sistemas de gestión según los detalles a continuación:"> -->
                                                                <!-- <b-form-checkbox-group :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" v-model.lazy="datosCotizacion.detallesImpactoCubren" :options="listaImpactoControles" stacked small plain value-field="idImpactoControles" text-field="descripcion"></b-form-checkbox-group> -->
                                                                <!-- </b-form-group> -->
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-form-group label="¿Son el aspecto y el impacto similares en todos los turnos?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isAspectoSimilar" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="12" v-if="datosCotizacion.isAspectoSimilar==1">
                                                                <validation-provider name="aspectos similares" v-slot="{errors}">
                                                                    <b-form-group label="Aspecto similares:" class="mb-2">
                                                                        <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaAspectosSimilares => listaAspectosSimilares.idAspectoSimilar" label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosCotizacion.detallesAspectoSimilar.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.detallesAspectoSimilar" :options="listaAspectosSimilares">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row v-if="disabledNorma3">
                                                            <b-col md="12" class="mb-2">
                                                                <span class="text-muted h5"><u>ISO 45001:2018</u></span>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-form-group label="¿El peligro y el riesgo abarcan alguno de los siguientes puntos y controles definidos?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isAbarcaPunto" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="12" v-if="datosCotizacion.isAbarcaPunto==1">
                                                                <validation-provider name="puntos y controles" v-slot="{errors}">
                                                                    <b-form-group label="Puntos y controles:" class="mb-2">
                                                                        <v-select :disabled="$route.params.id ? true : false" multiple :reduce="listaPuntoControles => listaPuntoControles.idPuntoControl" label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosCotizacion.detallesAbarcaPunto.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.detallesAbarcaPunto" :options="listaPuntoControles">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-form-group label="¿El peligro y el riesgo son similares en todos los turnos?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.riesgosSimilaresTurnos" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row v-if="disabledNorma4">
                                                            <b-col md="12" class="mb-2 mt-2">
                                                                <span class="text-muted h5"><u>ISO 37001:2016</u></span>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Existe un consejo de administración o la alta dirección de la organización se le asigna la responsabilidad del consejo de administración?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Existe un consejo de administración o la alta dirección de la organización se le asigna la responsabilidad del consejo de administración?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pq" v-model.lazy="datosCotizacion.consejoAdministracion" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Está establecida la función de cumplimiento antisoborno y se definen las responsabilidades y autoridades?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Está establecida la función de cumplimiento antisoborno y se definen las responsabilidades y autoridades?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pw" v-model.lazy="datosCotizacion.cumplimientoAntisoborno" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿La debida diligencia es parte del proceso ABMS de la organización establecido?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿La debida diligencia es parte del proceso ABMS de la organización establecido?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pe" v-model.lazy="datosCotizacion.diligenciaProcesoABMS" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Están establecidos y determinados los controles financieros requeridos por ABMS?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Están establecidos y determinados los controles financieros requeridos por ABMS?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pr" v-model.lazy="datosCotizacion.controlesFinancierosABMS" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Se establecen y determinan los controles no financieros exigidos por ABMS?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Se establecen y determinan los controles no financieros exigidos por ABMS?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pt" v-model.lazy="datosCotizacion.noControlesFinancierosABMS" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Existen políticas y procedimientos definidos, documentados para el manejo de obsequios, hospitalidad y donaciones?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Existen políticas y procedimientos definidos, documentados para el manejo de obsequios, hospitalidad y donaciones?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-py" v-model.lazy="datosCotizacion.obsequiosHospitalidad" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Existe un proceso establecido para la revisión de ABMS por órgano rector?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Existe un proceso establecido para la revisión de ABMS por órgano rector?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pu" v-model.lazy="datosCotizacion.revisionOrganoRector" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Existe un proceso establecido para la revisión de ABMS por función de cumplimiento antisoborno?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Existe un proceso establecido para la revisión de ABMS por función de cumplimiento antisoborno?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pi" v-model.lazy="datosCotizacion.revisionCumplimientoAntisoborno" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <!-- <b-col md="6">
                                                                <validation-provider name="Información comercial: ¿Hay procesos subcontratados?" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Información comercial: ¿Hay procesos subcontratados?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-po" v-model.lazy="datosCotizacion.hasProcesosSubcontratados" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col> -->
                                                            <b-col md="6">
                                                                <validation-provider name="¿Los socios comerciales están identificados y trabajan como parte del negocio de la organización? " :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Los socios comerciales están identificados y trabajan como parte del negocio de la organización?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pp" v-model.lazy="datosCotizacion.sociosComerciales" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="¿Se establece y determina la evaluación del riesgo de soborno? " :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="¿Se establece y determina la evaluación del riesgo de soborno?:">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" small plain id="radio-group-pa" v-model.lazy="datosCotizacion.evaluacionRiesgoAntisoborno" :options="comboSiNo" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.evaluacionRiesgoAntisoborno == 1">
                                                                <validation-provider name="indique los detalles de la evaluación" v-slot="validationContext">
                                                                    <b-form-group label="Indique los detalles de la evaluación:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle de la evaluación" v-model.lazy="datosCotizacion.detalleEvaluacionAntisoborno"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.evaluacionRiesgoAntisoborno == 1">
                                                                <validation-provider name="evaluación de riesgo antisoborno" v-slot="validationContext">
                                                                    <b-form-group label="Evaluación de riesgo antisoborno:">
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="datosCotizacion.nombreDocumentoEvaluacionAntisoborno != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosCotizacion.urlDocumentoEvaluacionAntisoborno)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-if="!$route.params.id" ref="fileAntisoborno" class="text-left" v-model.lazy="datosCotizacion.documentoEvaluacionAntisoborno" v-on:change="handleFileUploadEvaluacionAntisoborno" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                                                            <b-form-input v-if="$route.params.id" disabled class="disabled-white" :state="getValidationState(validationContext)" v-model.lazy="datosCotizacion.nombreDocumentoEvaluacionAntisoborno"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer5" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion4)">
                                                <b-tab :title-item-class="datosCotizacion.idCotizacionCertificacion=='' ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Procesos y producción
                                                    </template>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row v-if=" (disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 && (disabledNorma5 || disabledNorma6)) || (disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4)">
                                                            <b-col md="12">
                                                                <span class="text-muted h6">Cambios significativos en los sistemas de gestión de la organización en comparación con el proceso de certificación anterior para la recertificación / Ampliación del alcance / Certificación de transferencia • solo en:</span>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table bordered :items="disabledNorma4 ? datosCotizacion.informes : datosCotizacion.informes.filter(x =>x.soloEn != 37001) " :fields="campoInformes" class="mt-3" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <template #table-colgroup="campoInformes">
                                                                        <col v-for="field in campoInformes.fields" :key="field.key" :style="{ width: field.key == 'nombre' ? '25%' : field.key == 'estado' ? '20%' : '55%' }">
                                                                    </template>
                                                                    <template v-slot:cell(nombre)="row">
                                                                        <div class="text-left">
                                                                            <span>{{row.value}}</span>
                                                                        </div>

                                                                    </template>
                                                                    <template v-slot:cell(estado)="row">
                                                                        <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="row.item.estado" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                    </template>
                                                                    <template v-slot:cell(detalle)="row">
                                                                        <validation-provider :name="`index${row.index}`" v-slot="validationContext">
                                                                            <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle " v-model.lazy="row.item.detalle"></b-form-textarea>

                                                                        </validation-provider>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- 27001 -->
                                                        <b-row v-if="disabledNorma5 || disabledNorma6">
                                                            <b-col md="6">
                                                                <b-form-group label="Opera la organización en turno?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isOperaTurno" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.isOperaTurno == 1">
                                                                <validation-provider name="número de turno" v-slot="validationContext">
                                                                    <b-form-group label="Número de turno:" class="mb-2">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" placeholder="Ingrese el número del turno" v-model.lazy="datosCotizacion.numeroTurno"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>

                                                                <!-- <b-form-group label="Número de turno:" class="mb-2">
                                                                        <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" placeholder="Ingrese el número del turno" :value="datosCotizacion.numeroTurno" @change="datosCotizacion.numeroTurno = $event"></b-form-input>
                                                                    </b-form-group> -->
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group label="¿Son los procesos de desarrollo / fabricación los mismos en todos los turnos?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isProcesoDesarrolloTurno" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <!-- {{caja}} -->
                                                            <b-col md="6">
                                                                <validation-provider name="detalles de la operación en cada turno" v-slot="validationContext">
                                                                    <b-form-group label="Detalles de la operación en cada turno:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los detalles de la operación en cada turno" v-model.lazy="datosCotizacion.detallesOperacionTurno"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <span class="text-muted h6">Exclusión:</span>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group label="Cualquier exclusión tomada para cualquier cláusula (s) de los requisitos de ISO 27001: 2013 o It SMS 20000: 2011" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.exclusionSgsi" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.exclusionSgsi == 1">
                                                                <validation-provider name="detalles de exclusión" v-slot="validationContext">
                                                                    <b-form-group label="Indique los detalles de exclusión:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los detalles de la operación en cada turno" v-model.lazy="datosCotizacion.detalleExclusionSgsi"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col md="6">
                                                                <b-form-group label="Información de negocios: ¿Hay procesos subcontratados?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.hasProcesosSubcontratados" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.hasProcesosSubcontratados == 1">
                                                                <validation-provider name="proporcione detalles de los procesos subcontratados" v-slot="validationContext">
                                                                    <b-form-group label="Proporcione detalles de los procesos subcontratados:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los detalles de los procesos subcontratados" v-model.lazy="datosCotizacion.detalleProcesosSubcontratados"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="requisitos legales aplicables para el Producto" v-slot="validationContext">
                                                                    <b-form-group label="Requisitos legales aplicables para el Producto y / o Servicio y otras obligaciones legales:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los requisitos legales" v-model.lazy="datosCotizacion.requisitosLegales"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="detalles de los procesos de fabricación" v-slot="validationContext">
                                                                    <b-form-group label="Detalles de los procesos de fabricación / servicio: (Adjunte la lista. Si es necesario):" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los detalles de los procesos de fabricación" v-model.lazy="datosCotizacion.detallesProcesosFabricacion"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        <b-input-group class="mt-2">
                                                                            <b-input-group-prepend v-if="datosCotizacion.nombreDocumentoProcesoFabricacion != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosCotizacion.urlDocumentoProcesoFabricacion)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-if="!$route.params.id" ref="filey1" class="text-left" v-model.lazy="datosCotizacion.archivoProcesosFabricacion" v-on:change="handleFileUpload('y1',$event)" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                                                            <b-form-input v-if="$route.params.id" disabled class="disabled-white" :state="getValidationState(validationContext)" v-model.lazy="datosCotizacion.nombreDocumentoProcesoFabricacion"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                                <!-- <b-form-file v-if="!$route.params.id" class="mb-2" ref="filey1" v-model.lazy="datosCotizacion.archivoProcesosFabricacion" placeholder="Elija un archivo o arrástrelo aquí..." accept=".pdf,.docx, application/msword, application/vnd.ms-excel" browse-text="Subir" v-on:change="handleFileUpload('y1',$event)"></b-form-file> -->

                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="detalles de los productos fabricados" v-slot="validationContext">
                                                                    <b-form-group label="Detalles de los productos fabricados / servicios prestados: (Adjunte la lista. Si es necesario):" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los detalles de los productos fabricados" v-model.lazy="datosCotizacion.detallesProductosFabricados"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        <b-input-group class="mt-2">
                                                                            <b-input-group-prepend v-if="datosCotizacion.nombreDocumentoProductosFabricados != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosCotizacion.urlDocumentoProductosFabricados)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-if="!$route.params.id" ref="filey2" class="text-left" v-model.lazy="datosCotizacion.archivoProductosFabricados" v-on:change="handleFileUpload('y2',$event)" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                                                            <b-form-input v-if="$route.params.id" disabled class="disabled-white" :state="getValidationState(validationContext)" v-model.lazy="datosCotizacion.nombreDocumentoProductosFabricados"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                                <!-- <b-form-file v-if="!$route.params.id" class="mb-2" ref="filey2" v-model.lazy="datosCotizacion.archivoProductosFabricados" placeholder="Elija un archivo o arrástrelo aquí..." accept=".pdf,.docx, application/msword, application/vnd.ms-excel" browse-text="Subir" v-on:change="handleFileUpload('y2',$event)"></b-form-file> -->

                                                            </b-col>
                                                        </b-row>
                                                        <!-- 37001 -->
                                                        <b-row v-if="disabledNorma4">
                                                            <b-col md="6">
                                                                <b-form-group label="¿Hay alguna parte de la organización a saber, sucursales, proyectos o sitios que no están incluidos en el alcance de la certificación?" class="mb-2">
                                                                    <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isNoIncluidoAlcance" :options="[{value: 1, text: 'SI'},{value: 2, text: 'NO'}]" small plain></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosCotizacion.isNoIncluidoAlcance == 1">
                                                                <validation-provider name="indique los detalles" v-slot="validationContext">
                                                                    <b-form-group label="Indique los detalles" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los detalles " v-model.lazy="datosCotizacion.detalleIsNoIncluido"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col md="6">
                                                                <validation-provider name="representante autorizado del cliente que completa la información anterior" v-slot="validationContext">
                                                                    <b-form-group label="Representante autorizado del cliente que completa la información anterior:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el representante autorizado del cliente que completa la información anterior" v-model.lazy="datosCotizacion.representanteAutorizadoCliente"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="designación y fecha:" v-slot="validationContext">
                                                                    <b-form-group label="Designación y fecha:" class="mb-2">
                                                                        <b-form-textarea :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la designacion y fecha" v-model.lazy="datosCotizacion.designacionFecha"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer6" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(registrarCotizacion)">
                                                <b-tab :title-item-class="datosCotizacion.idCotizacionCertificacion=='' ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Cálculo de días y costos
                                                    </template>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-save"></i> Enviar
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>

                                                    <div class="mt-3">
                                                        <b-row>

                                                            <b-col md="12">
                                                                <b-row>
                                                                    <!-- LISTA LOS CAMPOS DEL VENDEDOR-->
                                                                    <b-col md="12">
                                                                        <validation-provider name="vendedor" rules="required" v-slot="{errors}">
                                                                            <b-form-group label="Vendedor:" class="mb-2">
                                                                                <v-select :disabled="$route.params.id ? true : false" :reduce="listaVendedores => listaVendedores.idVendedor" label="nombres" placeholder="Seleccione el vendedor" :class="{'style-valid-select': datosNuevoVendedor.idVendedor  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosNuevoVendedor.idVendedor" :options="listaVendedores">
                                                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                                </v-select>
                                                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>

                                                                    <b-col md="12" class="mb-2">
                                                                        <span class="text-muted h5"><u>Cálculo de días auditoría</u></span>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <b-form-group label="Tipo de cálculo" class="mb-2">
                                                                            <b-form-radio-group @change="calcularDiasAuditoria()" :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isTipoCalculoDias" :options="[{value: 1, text: 'Manual'},{value: 2, text: 'Automático'}]" small plain></b-form-radio-group>
                                                                        </b-form-group>
                                                                    </b-col>

                                                                    <b-col md="2">
                                                                        <b-form-group label="Tipo de auditoría" class="mb-2">
                                                                            <b-form-radio-group :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isTipoAuditoriaDias" :options="[{value: 1, text: 'Remota'},{value: 2, text: 'Presencial'}]" small plain></b-form-radio-group>
                                                                        </b-form-group>
                                                                    </b-col>
                                                                    <b-col md="3">
                                                                        <validation-provider name="total de días de auditoría in situ" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Total de días de auditoría in situ:" class="mb-2">
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.totalDiasInSitu"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="3">
                                                                        <validation-provider name="total de días gabinete" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Total dias gabinete:" class="mb-2">
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.totalDiasGabinete"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <validation-provider name="total de días auditor" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Total dias auditor:" class="mb-2">
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.totalDiasAuditor"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-row>
                                                                    <b-col md="12" class="my-2">
                                                                        <span class="text-muted h5"><u>Cálculo de costo</u></span>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <b-form-group label="Tipo de cálculo" class="mb-2">
                                                                            <b-form-radio-group @change="calcularCostoAuditoria()" :disabled="$route.params.id ? true : false" v-model.lazy="datosCotizacion.isTipoCalculoCosto" :options="[{value: 1, text: 'Manual'},{value: 2, text: 'Automático'}]" small plain></b-form-radio-group>
                                                                        </b-form-group>
                                                                    </b-col>
                                                                    <!--LISTA LOS CAMPOS MONEDA SOLES Y DÓLARES-->
                                                                    <b-col md="2">
                                                                        <validation-provider name="tipo moneda" rules="required" v-slot="{errors}">
                                                                            <b-form-group label="Seleccione el tipo de Moneda:" class="mb-2">
                                                                                <v-select :reduce="listaTipoMoneda => listaTipoMoneda.idMoneda" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCotizacion.moneda.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCotizacion.moneda" :options="listaTipoMoneda">
                                                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                                </v-select>
                                                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <!-- <b-col md="2">
                                                                        <validation-provider name="tipo moneda" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Seleccione el tipo de moneda:" class="mb-2">
                                                                                <b-form-input @change="convertirMoneda()" :disabled="$route.params.id ? true :false" :class="$route.params.id ? 'disabled-white' : ''" type="money" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.valorAuditoria"></b-form-input>

                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col> -->
                                                                    <b-col md="2">
                                                                        <validation-provider name="valor auditoría" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Valor auditoría:" class="mb-2">
                                                                                <b-form-input @input="calcularCostoAuditoria()" :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.valorAuditoria"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <validation-provider name="gastos operativos" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Gastos operativos:" class="mb-2">
                                                                                <b-form-input @input="calcularCostoAuditoria()" :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.gastosOperativos"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <validation-provider name="descuento 1" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Descuento 1:" class="mb-2">
                                                                                <b-form-input @input="calcularCostoAuditoria()" :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.descuento1"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <validation-provider name="descuento 2" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Descuento 2:" class="mb-2">
                                                                                <b-form-input @input="calcularCostoAuditoria()" :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.descuento2"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <validation-provider name="valor final" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Valor final:" class="mb-2">
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.valorFinal"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="2">
                                                                        <validation-provider name="precio (incluye IGV)" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Precio (incluye IGV):" class="mb-2">
                                                                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="0.00" v-model.lazy="datosCotizacion.valorFinalIGV"></b-form-input>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row v-if="!$route.params.id" class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-save"></i> Enviar
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>
                                    </b-tabs>

                                    <b-row v-if="$route.params.id" class="text-center mt-2">
                                        <b-col md="12">
                                            <b-button class="mr-2" variant="dark" :to="{name: 'Cotizaciones certificacion'}" type="button">
                                                <i class="fas fa-arrow-left"></i> Volver
                                            </b-button>
                                        </b-col>
                                    </b-row>

                                    <!--  </b-form> -->
                                    <!-- </validation-observer> -->
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

    <CModal color="primario" :closeOnBackdrop="false" :show.sync="modalAgregarPersonal">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-cog fa-md"></i>
                <span class="h5"> Agregar empleados</span></h6>
            <CButtonClose @click="modalAgregarPersonal = false" class="text-white" />
        </template>
        <validation-observer ref="observer33" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(agregarPersonal)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="tipo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo:" class="mb-2">
                                <b-form-select v-model.lazy="datosPersonal.tipo" :state="getValidationState(validationContext)" :options="comboTipos">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="cargo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Cargo:" class="mb-2">
                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese el cargo" v-model.lazy="datosPersonal.cargo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="turno general" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Turno general:" class="mb-2">
                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese la cantidad" v-model.lazy="datosPersonal.turnoGeneral"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="turno 1" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Turno 1:" class="mb-2">
                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese la cantidad" v-model.lazy="datosPersonal.turno1"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="turno 2" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Turno 2:" class="mb-2">
                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese la cantidad" v-model.lazy="datosPersonal.turno2"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="turno 3" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Turno 3:" class="mb-2">
                                <b-form-input :disabled="$route.params.id ? true : false" :class="$route.params.id ? 'disabled-white' : ''" :state="getValidationState(validationContext)" type="text" placeholder="Ingrese la cantidad" v-model.lazy="datosPersonal.turno3"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button variant="success" class=" mr-2" type="submit">
                            Agregar
                        </b-button>
                        <b-button variant="danger" @click="modalAgregarPersonal = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import translate from "translate";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            editorOption: {
                placeholder: 'Escriba aqui...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        /* ['blockquote', 'code-block'], */
                        [{
                            'header': 1
                        }, {
                            'header': 2
                        }],
                        [{
                            'list': 'ordered'
                        }, {
                            'list': 'bullet'
                        }],
                        [{
                            'script': 'sub'
                        }, {
                            'script': 'super'
                        }],
                        [{
                            'indent': '-1'
                        }, {
                            'indent': '+1'
                        }],
                        [{
                            'direction': 'rtl'
                        }],
                        [{
                            'size': ['small', false, 'large', 'huge']
                        }],
                        [{
                            'header': [1, 2, 3, 4, 5, 6, false]
                        }],
                        [{
                            'font': []
                        }],
                        [{
                            'color': []
                        }, {
                            'background': []
                        }],
                        [{
                            'align': []
                        }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    syntax: {
                        highlight: text => hljs.highlightAuto(text).value
                    }
                }
            },
             //SOLES O DOLARES
                listaTipoMoneda:[{
                    idMoneda: 1,
                    descripcion: 'S/.:',
                },{
                    idMoneda:2,
                    descripcion: '$:'
                },
                ],
            datosCotizacion: {
                idCotizacionCertificacion: '',
                idDetalleCotizacionCertificacionA: '',
                idDetalleCotizacionCertificacionB: '',
                idDetalleCotizacionCertificacionCSGA: '',
                idDetalleCotizacionCertificacionCSGAS: '',
                idDetalleCotizacionCertificacionCSGC: '',
                idDetalleCotizacionCertificacionCSGSI: '',
                idDetalleCotizacionCertificacionCSGSST: '',
                idDetalleCotizacionCertificacionD: '',
                idCliente: null,
                moneda: [],

                //DatosGenerales
                idSubCliente: null,
                normas: [],
                integraciones: [],
                tipoCertificaciones: [],
                tipoAuditorias: [],
                tipoAcreditacion: [],
                nombreOrganismo: '',
                cuerpoAcreditacion: '',
                numeroCertificado: '',
                fechaVencimiento: moment().format('YYYY-MM-DD'),
                tipoUltimaAuditoria: '',
                alcanceCertificacion: '',
            

                //Sección 1
                ubicacionesAmbito: [],
                numeroSucursales: '',
                manoObraSucursal: '',
                totalEmpleados: '',
                totalEmpleadosCompleto: '',
                totalEmpleadosParcial: '',
                totalEmpleadosEstacional: '',
                totalEmpleadosProyecto: '',
                fechaImplementacionIMS: moment().format('YYYY-MM-DD'),
                acreditacionesRequeridad: [],
                metodologiasAuditoria: [],
                disposicionAuditoriaRemota: 1,
                detallesAuditoriaRemota: '',
                infraestructurasAuditoriaRemota: [],
                representanteLocal: '',

                //MONEDA SOLES O DOLARES

                //Sección 2
                detalleEmpresa: [{
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                    {
                        descripcion: '',
                        proceso: ''
                    },
                ],
               
                numeroTotalEmpleados: '',
                listaPersonal: [],
                hasDocumentadoOrganigrama: 1,
                documentoOrganigrma: null,
                urlDocumentoOrganigrama: '',
                nombreDocumentoOrganigrama: '',
                manoObraTotal1: '',
                manoObraTotal2: '',
                proyectoManoObra: '',
                operacionesPorTurnos: 1,
                numeroTurnos: '',
                tiempo: '',
                procesoFabricacion: 1,
                detalleOperacion: '',
                idiomaComunicacion: '',
                servicioConsultoria: 1,
                nombreConsultor: '',

                //Sección 3
                nivelesIntegracion: [],
                isProcesoAplicable: 1,
                detalleProcesoAplicable: '',
                detalleProcesoNoAplicable: '',
                isProductoNoIncluido: 1,
                detalleProductoNoIncluido: '',
                isImpactoCubren: 1,
                detallesImpactoCubren: [],
                isAspectoSimilar: 1,
                detallesAspectoSimilar: [],
                isAbarcaPunto: 1,
                detallesAbarcaPunto: [],
                riesgosSimilaresTurnos: 1,
                listaTipoOrganizacion: [],
                centroDatos: [],
                servidores: [],
                facilidadAccesoRemoto: 1,
                detalleInfraestructura: 1,
                vistaMacroHeramientas: 1,
                protocolos: 1,
                numeroEnrutadores: 1,
                mecanismoControlAcceso: 1,
                dispositivosInformaticos: 1,
                seguridadFisica: 1,
                centrosDesarrollo: 1,
                sitioRecuperacionDesastres: 1,
                consejoAdministracion: 1,
                cumplimientoAntisoborno: 1,
                diligenciaProcesoABMS: 1,
                controlesFinancierosABMS: 1,
                noControlesFinancierosABMS: 1,
                obsequiosHospitalidad: 1,
                revisionOrganoRector: 1,
                revisionCumplimientoAntisoborno: 1,
                sociosComerciales: 1,
                evaluacionRiesgoAntisoborno: 1,
                detalleEvaluacionAntisoborno: '',
                nombreDocumentoEvaluacionAntisoborno: '',
                urlDocumentoEvaluacionAntisoborno: '',
                documentoEvaluacionAntisoborno: null,

                //Sección 4
                informes: [{
                        idInforme: 1,
                        nombre: 'Contexto de la organización:',
                        estado: 1,
                        detalle: ''
                    },
                    {
                        idInforme: 2,
                        nombre: 'Alta Dirección / Junta de Gobierno',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001
                    },
                    {
                        idInforme: 3,
                        nombre: 'Área geográfica de operaciones',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001

                    },
                    {
                        idInforme: 4,
                        nombre: 'Estructura de organización:',
                        estado: 1,
                        detalle: ''
                    }, {
                        idInforme: 5,
                        nombre: 'Cambios tecnológicos con impacto en procesos y productos:',
                        estado: 1,
                        detalle: ''
                    }, {
                        idInforme: 6,
                        nombre: 'Empleados (aumento / disminución)',
                        estado: 1,
                        detalle: ''
                    },
                    {
                        idInforme: 7,
                        nombre: 'Empleados (función, cargos)',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001

                    },
                    {
                        idInforme: 8,
                        nombre: 'Socios de negocio',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001

                    },
                    {
                        idInforme: 9,
                        nombre: 'Entidades sobre las que se ejercen controles o viceversa',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001

                    },
                    {
                        idInforme: 10,
                        nombre: 'Actividades de subcontratación, si las hubiera',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001

                    },
                    {
                        idInforme: 11,
                        nombre: 'Canal de comunicación con funcionarios públicos',
                        estado: 1,
                        detalle: '',
                        soloEn: 37001

                    },
                    {
                        idInforme: 12,
                        nombre: 'Alcance de la certificación:',
                        estado: 1,
                        detalle: ''
                    }
                ],
                isOperaTurno: 1,
                numeroTurno: '',
                isProcesoDesarrolloTurno: 1,
                detallesOperacionTurno: '',
                exclusionSgsi: 1,
                detalleExclusionSgsi: '',
                hasProcesosSubcontratados: 1,
                detalleProcesosSubcontratados: '',
                requisitosLegales: '',
                detallesProcesosFabricacion: '',
                archivoProcesosFabricacion: null,
                detallesProductosFabricados: '',
                archivoProductosFabricados: null,
                nombreDocumentoProcesoFabricacion: '',
                nombreDocumentoProductosFabricados: '',
                urlDocumentoProcesoFabricacion: '',
                urlDocumentoProductosFabricados: '',
                isNoIncluidoAlcance: 1,
                detalleIsNoIncluido: '',
                representanteAutorizadoCliente: '',
                designacionFecha: '',

                //sección 5
                isTipoCalculoDias: 1,
                isTipoAuditoriaDias: 1,
                totalDiasInSitu: 0,
                totalDiasGabinete: 0,
                totalDiasAuditor: 0,
                isTipoCalculoCosto: 1,
                valorAuditoria: 0,
                gastosOperativos: 0,
                descuento1: 0,
                descuento2: 0,
                valorFinal: 0,
                igv: 0,
                valorFinalIGV: 0,
            },

            campoInformes: [{
                    key: "nombre",
                    label: "Informe detallado",
                    class: "text-center",
                },
                {
                    key: "estado",
                    label: "Si hay algún cambio",
                    class: "text-center",
                },
                {
                    key: "detalle",
                    label: "Si es así, especifique los cambios",
                    class: "text-center",
                },
            ],
            //Disabled
            disabledNorma1: false,
            disabledNorma2: false,
            disabledNorma3: false,
            disabledNorma4: false,
            disabledNorma5: false,
            disabledNorma6: false,
            //Listas
            listaClientes: [],
            //AGREGACIÓN NUEVO VENDEDOR
            listaVendedores: [],
            datosNuevoVendedor: {
                idVendedor: '',
                idCliente: '',
                nombres: '',
                importeComision: 0
            },
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            listaIntegracion: [{
                    idIntegracion: 1,
                    nombre: 'Auditoría conjunta'
                },
                {
                    idIntegracion: 2,
                    nombre: 'Auditoría combinada'
                }, {
                    idIntegracion: 3,
                    nombre: 'Auditoría del SG integrado'
                }, {
                    idIntegracion: 4,
                    nombre: 'Auditoría remota'
                }
            ],

            listaTipoCertificacionSolicitada: [{
                idTipoCertificacionSolicitada: 1,
                nombre: 'Certificación inicial'
            }, {
                idTipoCertificacionSolicitada: 2,
                nombre: 'Recertificación'
            }, {
                idTipoCertificacionSolicitada: 3,
                nombre: 'Certificación de transferencia'
            }, {
                idTipoCertificacionSolicitada: 4,
                nombre: 'Ampliación del alcance'
            }, {
                idTipoCertificacionSolicitada: 5,
                nombre: 'Seguimiento'
            }, ],

            listaTipoAuditorias: [],


            listaUbicaciones: [],

            listaTipoDeAcreditacion: [{
                idTipoDeAcreditacion: 1,
                nombre: 'Ias'
            }, {
                idTipoDeAcreditacion: 2,
                nombre: 'Inacai'
            }, {
                idTipoDeAcreditacion: 3,
                nombre: 'Jasanz'
            }, ],

            listaAcreditaciones: [],
            listaMetodologiasAuditoria: [{
                    idMetodologiaAuditoria: 1,
                    descripcion: 'Auditoría in situ'
                },
                {
                    idMetodologiaAuditoria: 2,
                    descripcion: 'Auditoría remota'
                },
            ],
            listaInfraestructurasRemota: [{
                    idInfraestructurasRemota: 1,
                    descripcion: 'Smartphones'
                },
                {
                    idInfraestructurasRemota: 2,
                    descripcion: 'Dispositivos de mano'
                },
                {
                    idInfraestructurasRemota: 3,
                    descripcion: 'Computadoras portatiles'
                },
                {
                    idInfraestructurasRemota: 4,
                    descripcion: 'Computadoras de escritorio'
                },
                {
                    idInfraestructurasRemota: 5,
                    descripcion: 'Videoconferencia'
                },
            ],

            listaNivelIntegracion: [{
                idNivelIntegracion: 1,
                descripcion: '1. Un conjunto de documentación integrado'
            }, {
                idNivelIntegracion: 2,
                descripcion: '2. Revisiones de la gerencia considerando la estrategia y el plan comerciales generales'
            }, {
                idNivelIntegracion: 3,
                descripcion: '3. Enfoque integrado de las auditorías internas'
            }, {
                idNivelIntegracion: 4,
                descripcion: '4. Enfoque integrado de las políticas y objetivos'
            }, {
                idNivelIntegracion: 5,
                descripcion: '5. Enfoque integrado de los procesos de los sistemas'
            }, {
                idNivelIntegracion: 6,
                descripcion: '6. Enfoque integrado de los mecanismos de mejora (acción correctiva y preventiva; medición y mejora continua)'
            }, {
                idNivelIntegracion: 7,
                descripcion: '7. Apoyo y responsabilidades de gestión integrados'
            }, ],

            listaImpactoControles: [{
                idImpactoControles: 1,
                descripcion: 'Emisiones al aire'
            }, {
                idImpactoControles: 2,
                descripcion: 'Liberaciones al agua'
            }, {
                idImpactoControles: 3,
                descripcion: 'Energía emitida, p. Ej. Calor, radiación, vibración'
            }, {
                idImpactoControles: 4,
                descripcion: 'Residuos y subproductos'
            }, {
                idImpactoControles: 5,
                descripcion: 'Uso de energía'
            }, {
                idImpactoControles: 6,
                descripcion: 'Lanzamientos a tierra'
            }, {
                idImpactoControles: 7,
                descripcion: 'Atributos físicos, por ejemplo, tamaño, forma, color, apariencia'
            }, {
                idImpactoControles: 8,
                descripcion: 'Uso de materias primas y recursos naturales '
            }, ],

            listaAspectosSimilares: [{
                idAspectoSimilar: 1,
                descripcion: 'BS 18001: 2007 OHSAS'
            }, {
                idAspectoSimilar: 2,
                descripcion: 'ISO 45001: 2018 OHSMS'
            }, {
                idAspectoSimilar: 3,
                descripcion: 'AS 4801: 2001 (solo para el mercado de Australia y Nueva Zelanda)'
            }],
            listaPuntoControles: [{
                idPuntoControl: 1,
                descripcion: 'Trabajo en alturas'
            }, {
                idPuntoControl: 2,
                descripcion: 'Permiso para trabajar'
            }, {
                idPuntoControl: 3,
                descripcion: 'Aislamiento de energía'
            }, {
                idPuntoControl: 4,
                descripcion: 'Acceso a espacios confinados'
            }, {
                idPuntoControl: 5,
                descripcion: 'Análisis de seguridad laboral'
            }, {
                idPuntoControl: 6,
                descripcion: 'Operaciones de elevación'
            }, {
                idPuntoControl: 7,
                descripcion: 'Bastidores de almacenamiento'
            }, {
                idPuntoControl: 8,
                descripcion: 'Estrés térmico'
            }, {
                idPuntoControl: 9,
                descripcion: 'Equipo crítico y anulación del sistema'
            }, {
                idPuntoControl: 10,
                descripcion: 'Equipo de protección personal'
            }, {
                idPuntoControl: 11,
                descripcion: 'Control de productos / servicios adquiridos'
            }, {
                idPuntoControl: 12,
                descripcion: 'Gestión de visitantes al lugar de trabajo'
            }, {
                idPuntoControl: 13,
                descripcion: 'Almacenamiento de materiales peligrosos'
            }, {
                idPuntoControl: 14,
                descripcion: 'Almacenamiento y manipulación de materiales radiactivos'
            }, {
                idPuntoControl: 15,
                descripcion: 'Seguridad en el entorno de la oficina'
            }, {
                idPuntoControl: 16,
                descripcion: 'Manipulación segura de productos químicos'
            }, {
                idPuntoControl: 17,
                descripcion: 'Manejo seguro de maquinarias, equipos, materiales'
            }, {
                idPuntoControl: 18,
                descripcion: 'Almacenamiento de productos elaborados / productos, ácidos, álcalis, etc.'
            }, {
                idPuntoControl: 19,
                descripcion: 'Ambiente de trabajo para almacenamiento como control de temperatura / control de plagas'
            }, {
                idPuntoControl: 20,
                descripcion: 'Ambiente de trabajo para control de humedad / ventilación adecuada / mantenimiento de la casa'
            }, ],

            comboTipoOrganizacion: [{
                    value: 1,
                    text: 'Desarrollo de software'
                },
                {
                    value: 2,
                    text: 'Servicios de apoyo'
                },
                {
                    value: 3,
                    text: 'Ingeniería'
                },
                {
                    value: 4,
                    text: 'BPO'
                },
                {
                    value: 5,
                    text: 'Investigación'
                },
                {
                    value: 6,
                    text: 'Automotor'
                },
                {
                    value: 7,
                    text: 'Gestión de proyectos'
                },
                {
                    value: 8,
                    text: 'Gestión de proyectos'
                },
                {
                    value: 9,
                    text: 'Educativo'
                },
                {
                    value: 10,
                    text: 'Farmacéutico'
                },
                {
                    value: 11,
                    text: 'Bancos'
                },
                {
                    value: 12,
                    text: 'Cuidado de la salud'
                },
                {
                    value: 13,
                    text: 'Hospitalidad'
                },
                {
                    value: 14,
                    text: 'Alimentos / Químicos'
                },
                {
                    value: 15,
                    text: 'Administración Pública'
                },
                {
                    value: 16,
                    text: 'Otros'
                },
            ],
            camposAplicaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "aplicacion",
                    label: "Acplicaciones utilizadas para operaciones",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            listaAplicaciones: [{
                idAplicacion: 0,
                aplicacion: '',
                estado: 2,
            }],
            camposAplicacionesSoporte: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "aplicacion",
                    label: "Aplicaciones utilizadas para soporte de SGSI",
                    class: "text-center",
                },
                {
                    key: "numero",
                    label: "Número de aplicaciones",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            listaAplicacionesSoporte: [{
                idAplicacionSoporte: 0,
                aplicacion: '',
                numero: '',
                estado: 2,
            }],
            comboCentroDatos: [{
                    value: 1,
                    text: 'En casa'
                },
                {
                    value: 2,
                    text: 'Subcontratado'
                }
            ],
            comboServidores: [{
                    value: 1,
                    text: 'Windows',
                },
                {
                    value: 2,
                    text: 'Linux'
                }
            ],

            //Data sección 2
            modalAgregarPersonal: false,
            camposDetalleEmpresa: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: "documento",
                label: "Documentos detalle sucursal",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],
            listaDocumentoEmpresaSucursal: [{
                idDocumentoSucursal: 0,
                documento: null,
                nombreDocumento: '',
                urlDocumento: '',
                estado: 2,
            }],
            campoPersonal: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "cargo",
                    label: "Cargo",
                    class: "text-center",
                },
                {
                    key: "turnoGeneral",
                    label: "Turno general",
                    class: "text-center",
                },
                {
                    key: "turno1",
                    label: "Turno 1",
                    class: "text-center",
                },
                {
                    key: "turno2",
                    label: "Turno 2",
                    class: "text-center",
                },
                {
                    key: "turno3",
                    label: "Turno 3",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            datosPersonal: {
                cargo: '',
                tipo: null,
                turnoGeneral: '',
                turno1: '',
                turno2: '',
                turno3: '',
            },
            comboSiNo: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                }
            ],
            comboProcesoFabricacion: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                },
                {
                    value: 3,
                    text: 'NO APLICA'
                }
            ],
            comboTipos: [{
                    value: 1,
                    text: 'Alta dirección'
                },
                {
                    value: 2,
                    text: 'Gerentes, ingenieros, supervisores y otros'
                },
                {
                    value: 3,
                    text: 'Técnicos comerciales'
                }
            ],
            tabIndex: 0,
        }
    },
    methods: {
        handleInput() {
            this.listaTipoAuditorias = [];
            let newArray = [{
                idTipoAuditoria: 2,
                descripcion: 'SAI: 1ra auditoría de vigilancia',
            }, {
                idTipoAuditoria: 3,
                descripcion: 'SAII: 2da auditoría de vigilancia',
            }]

            this.datosCotizacion.tipoAuditorias = [];
            if (this.datosCotizacion.tipoCertificaciones == 2) {

                this.listaTipoAuditorias.push({
                    idTipoAuditoria: 1,
                    descripcion: 'RC: Recertificación',
                })

            } else {
                this.listaTipoAuditorias = [...newArray];
            }

        },
        descargarDocumento(url) {
            window.open(url)
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        datosGenerales() {
            this.tabIndex += 1;
        },
        seccion1() {
            this.tabIndex += 1;
        },
        seccion2() {
            this.tabIndex += 1;
        },
        seccion3() {
            this.tabIndex += 1;
        },
        seccion4() {
            this.tabIndex += 1;
        },
        /* seccion5() {
            console.log("enviar")
        }, */
        anterior() {
            this.tabIndex -= 1;
        },
        handleFileUpload(ident, e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file${ident}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`file${ident}`].reset();
                    return;
                }
                //this.listaDocumentoEmpresaSucursal.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadOrganigrama(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileOrg"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileOrg"].reset();
                    return;
                }
                //this.listaDocumentoEmpresaSucursal.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadEvaluacionAntisoborno(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileAntisoborno"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileAntisoborno"].reset();
                    return;
                }
                //this.listaDocumentoEmpresaSucursal.documento = this.$refs.file.files[0];
            }
        },

        checkNormas(e) {
            !!e.find(x => x == 1) ? this.disabledNorma1 = true : this.disabledNorma1 = false;
            !!e.find(x => x == 2) ? this.disabledNorma2 = true : this.disabledNorma2 = false;
            !!e.find(x => x == 3) ? this.disabledNorma3 = true : this.disabledNorma3 = false;
            !!e.find(x => x == 4) ? this.disabledNorma4 = true : this.disabledNorma4 = false;
            !!e.find(x => x == 5) ? this.disabledNorma5 = true : this.disabledNorma5 = false;
            !!e.find(x => x == 6) ? this.disabledNorma6 = true : this.disabledNorma6 = false;
            this.checkUbicaciones();
            this.checkAcreditaciones();
        },

        checkAcreditaciones() {
            let listaAcre = [{
                    idAcreditacion: 1,
                    descripcion: 'IAS'
                },
                {
                    idAcreditacion: 2,
                    descripcion: 'JASANS'
                },
                {
                    idAcreditacion: 3,
                    descripcion: 'INACAI'
                },
            ];

            // this.listaAcreditaciones = listaAcre;
            // this.datosCotizacion.acreditacionesRequeridad = [];

            if ((this.disabledNorma1 || this.disabledNorma2 || this.disabledNorma3) && this.disabledNorma4) {
                this.listaAcreditaciones = listaAcre;
            } else if ((this.disabledNorma3 || this.disabledNorma5 || this.disabledNorma6) && this.disabledNorma4) {
                this.listaAcreditaciones = listaAcre.filter(x => x.idAcreditacion == 2 || x.idAcreditacion == 3);
            } else if (this.disabledNorma1 || this.disabledNorma2) {
                this.listaAcreditaciones = listaAcre.filter(x => x.idAcreditacion == 1 || x.idAcreditacion == 2 || x.idAcreditacion == 3);
            } else if (this.disabledNorma3 || this.disabledNorma5 || this.disabledNorma6) {
                this.listaAcreditaciones = listaAcre.filter(x => x.idAcreditacion == 2);
            } else if (this.disabledNorma4) {
                this.listaAcreditaciones = listaAcre.filter(x => x.idAcreditacion == 3);
            } else if (!this.disabledNorma1 && !this.disabledNorma2 && !this.disabledNorma3 && !this.disabledNorma4 && !this.disabledNorma5 && !this.disabledNorma6) {
                this.listaAcreditaciones = []
            } else {
                this.listaAcreditaciones = []
            }

        },

        checkUbicaciones() {
            let listaUbi = [{
                idUbicacion: 1,
                descripcion: 'Ofinica registrada'
            }, {
                idUbicacion: 2,
                descripcion: 'Planta'
            }, {
                idUbicacion: 3,
                descripcion: 'Ofinica regional'
            }, {
                idUbicacion: 4,
                descripcion: 'Sucursal'
            }, {
                idUbicacion: 5,
                descripcion: 'Sitio temporal (s)'
            }, {
                idUbicacion: 7,
                descripcion: 'Centros de desarrollo'
            }, {
                idUbicacion: 8,
                descripcion: 'Centros de apoyo'
            }, {
                idUbicacion: 9,
                descripcion: 'Centros de desarrollo costa afuera (ODEC, si corresponde)'
            }, {
                idUbicacion: 10,
                descripcion: 'Sitio de recuperación ante desastres'
            }]

            if ((this.disabledNorma1 || this.disabledNorma2 || this.disabledNorma3 || this.disabledNorma4) && this.disabledNorma5 || this.disabledNorma6) {
                this.listaUbicaciones = listaUbi;
            } else if (this.disabledNorma1 || this.disabledNorma2 || this.disabledNorma3 || this.disabledNorma4) {
                this.listaUbicaciones = listaUbi.filter(x => x.idUbicacion == 1 || x.idUbicacion == 2 || x.idUbicacion == 3 || x.idUbicacion == 4 || x.idUbicacion == 5);
            } else if (this.disabledNorma5 || this.disabledNorma6) {
                this.listaUbicaciones = listaUbi.filter(x => x.idUbicacion == 1 || x.idUbicacion == 3 || x.idUbicacion == 7 || x.idUbicacion == 8 || x.idUbicacion == 8 || x.idUbicacion == 9 || x.idUbicacion == 10);
            } else if (!this.disabledNorma1 && !this.disabledNorma2 && !this.disabledNorma3 && !this.disabledNorma4 && !this.disabledNorma5 && !this.disabledNorma6) {
                // this.listaUbicaciones = listaUbi.filter(x => x.idUbicacion == 1 || x.idUbicacion == 3)
                this.listaUbicaciones = [];
            } else {
                // this.listaUbicaciones = listaUbi.filter(x => x.idUbicacion == 1 || x.idUbicacion == 3)
                this.listaUbicaciones = [];
            }
        },
        checkListaInformes() {
            return this.datosCotizacion.informes;
        },
        agregarPersonal() {
            let me = this;
            me.datosCotizacion.listaPersonal.push({
                idPersonal: '',
                cargo: me.datosPersonal.cargo,
                tipo: me.datosPersonal.tipo,
                turnoGeneral: me.datosPersonal.turnoGeneral,
                turno1: me.datosPersonal.turno1 ? me.datosPersonal.turno1 : '0',
                turno2: me.datosPersonal.turno2 ? me.datosPersonal.turno2 : '0',
                turno3: me.datosPersonal.turno3 ? me.datosPersonal.turno3 : '0',
                estado: 2
            });

            me.modalAgregarPersonal = false;

            let manoObraA = 0;
            let manoObraB = 0;
            for (const i in me.datosCotizacion.listaPersonal) {
                if (me.datosCotizacion.listaPersonal[i].tipo == 1 || me.datosCotizacion.listaPersonal[i].tipo == 2) {
                    manoObraA = manoObraA + parseInt(me.datosCotizacion.listaPersonal[i].turnoGeneral) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno1) + parseInt(me.datosCotizacion.listaPersonal[i].turno2) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno3);
                }
                if (me.datosCotizacion.listaPersonal[i].tipo == 3) {
                    manoObraB = manoObraB + parseInt(me.datosCotizacion.listaPersonal[i].turnoGeneral) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno1) + parseInt(me.datosCotizacion.listaPersonal[i].turno2) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno3);;
                }
            }
            me.datosCotizacion.manoObraTotal1 = manoObraA;
            me.datosCotizacion.manoObraTotal2 = manoObraB;
            me.datosCotizacion.proyectoManoObra = manoObraA + manoObraB;
        },
        agregarFilaSurcursal() {
            this.listaDocumentoEmpresaSucursal.push({
                idDocumentoSucursal: 0,
                documento: null,
                nombreDocumento: '',
                urlDocumento: '',
                estado: 2,
            })
        },
        quitarFilaSucursal(param) {
            let me = this;
            let listaDoc = me.listaDocumentoEmpresaSucursal.filter(x => x.estado == 2);
            if (listaDoc[param.index].idDocumentoSucursal) {
                listaDoc[param.index].estado = 1;
            } else if (listaDoc[param.index].idDocumentoSucursal == 0) {
                listaDoc[param.index].estado = 0;
                for (let e in me.listaDocumentoEmpresaSucursal) {
                    if (me.listaDocumentoEmpresaSucursal[e].estado == 0) {
                        me.listaDocumentoEmpresaSucursal.splice(e, 1);
                    }
                }
            }
        },
        eliminarPersonal(param) {
            let me = this;
            let listaPersonal = me.datosCotizacion.listaPersonal.filter(x => x.estado == 2);
            if (listaPersonal[param.index].idPersonal) {
                listaPersonal[param.index].estado = 1;
            } else if (listaPersonal[param.index].idPersonal == 0) {
                listaPersonal[param.index].estado = 0;
                for (let e in me.datosCotizacion.listaPersonal) {
                    if (me.datosCotizacion.listaPersonal[e].estado == 0) {
                        me.datosCotizacion.listaPersonal.splice(e, 1);
                    }
                }
            }
            let manoObraA = 0;
            let manoObraB = 0;
            for (const i in me.datosCotizacion.listaPersonal) {
                if (me.datosCotizacion.listaPersonal[i].tipo == 1 || me.datosCotizacion.listaPersonal[i].tipo == 2) {
                    manoObraA = manoObraA + parseInt(me.datosCotizacion.listaPersonal[i].turnoGeneral) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno1) + parseInt(me.datosCotizacion.listaPersonal[i].turno2) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno3);
                }
                if (me.datosCotizacion.listaPersonal[i].tipo == 3) {
                    manoObraB = manoObraB + parseInt(me.datosCotizacion.listaPersonal[i].turnoGeneral) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno1) + parseInt(me.datosCotizacion.listaPersonal[i].turno2) +
                        parseInt(me.datosCotizacion.listaPersonal[i].turno3);;
                }
            }
            me.datosCotizacion.manoObraTotal1 = manoObraA;
            me.datosCotizacion.manoObraTotal2 = manoObraB;
            me.datosCotizacion.proyectoManoObra = manoObraA + manoObraB;
        },
        agregarFilaAplicacion() {
            this.listaAplicaciones.push({
                idAplicacion: 0,
                aplicacion: '',
                estado: 2,
            })
        },
        quitarFilaAplicacion(param) {
            let me = this;
            let listaApp = me.listaAplicaciones.filter(x => x.estado == 2);
            if (listaApp[param.index].idAplicacion) {
                listaApp[param.index].estado = 1;
            } else if (listaApp[param.index].idAplicacion == 0) {
                listaApp[param.index].estado = 0;
                for (let e in me.listaAplicaciones) {
                    if (me.listaAplicaciones[e].estado == 0) {
                        me.listaAplicaciones.splice(e, 1);
                    }
                }
            }
        },
        agregarFilaAplicacionSoporte() {
            this.listaAplicacionesSoporte.push({
                idAplicacionSoporte: 0,
                aplicacion: '',
                numero: '',
                estado: 2,
            })
        },
        quitarFilaAplicacionSoporte(param) {
            let me = this;
            let listaApp = me.listaAplicacionesSoporte.filter(x => x.estado == 2);
            if (listaApp[param.index].idAplicacionSoporte) {
                listaApp[param.index].estado = 1;
            } else if (listaApp[param.index].idAplicacionSoporte == 0) {
                listaApp[param.index].estado = 0;
                for (let e in me.listaAplicacionesSoporte) {
                    if (me.listaAplicacionesSoporte[e].estado == 0) {
                        me.listaAplicacionesSoporte.splice(e, 1);
                    }
                }
            }
        },
        resetModalAgregarPersonal() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosPersonal.cargo = '';
            this.datosPersonal.tipo = null;
            this.datosPersonal.turnoGeneral = '';
            this.datosPersonal.turno1 = '';
            this.datosPersonal.turno2 = '';
            this.datosPersonal.turno3 = '';
        },
        calcularCostoAuditoria() {
            if (this.datosCotizacion.isTipoCalculoCosto == 2) {
                this.datosCotizacion.valorFinal = (parseFloat(this.datosCotizacion.valorAuditoria) + parseFloat(this.datosCotizacion.gastosOperativos)) - (parseFloat(this.datosCotizacion.descuento1) + parseFloat(this.datosCotizacion.descuento2)).toFixed(2)
                this.datosCotizacion.valorFinalIGV = (this.datosCotizacion.valorFinal + (this.datosCotizacion.valorFinal * 0.18)).toFixed(2);
            }
        },
        calcularDiasAuditoria() {
            if (this.datosCotizacion.isTipoCalculoDias == 2) {
                let x = this.datosCotizacion.numeroTotalEmpleados;
                let dias;
                if (x >= 1 && x <= 5) {
                    dias = 1.5;
                } else if (x >= 6 && x <= 10) {
                    dias = 2;
                } else if (x >= 11 && x <= 15) {
                    dias = 2.5;
                } else if (x >= 16 && x <= 25) {
                    dias = 3;
                } else if (x >= 26 && x <= 45) {
                    dias = 4;
                } else if (x >= 46 && x <= 65) {
                    dias = 5;
                } else if (x >= 66 && x <= 85) {
                    dias = 6;
                } else if (x >= 86 && x <= 125) {
                    dias = 7;
                } else if (x >= 126 && x <= 175) {
                    dias = 8;
                } else if (x >= 176 && x <= 275) {
                    dias = 9;
                } else if (x >= 276 && x <= 425) {
                    dias = 10;
                } else if (x >= 426 && x <= 625) {
                    dias = 11;
                }
                this.datosCotizacion.totalDiasAuditor = dias;

            }
        },
        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-clientes-iqc", {
                    params: {
                        estado: 2,
                        idCliente:me.$store.state.user.uidClient
                    }
                })
                .then(function (response) {
                    me.listaClientes = response.data;
                    me.filasTotales = me.listaClientes.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        // listarClientes() {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(CONSTANTES.URL_RUTA_SERVICIOS + "", {
        //             params: {
        //                estado:2
        //             }
        //         })
        //         .then(function (response) {
        //             me.listaClientes = response.data.filter(x => x.estado == 1 || x.estado == 2);

        //             if (me.$route.params.idSubCliente) {
        //                 me.datosCotizacion.idSubCliente = me.listaClientes.filter(x => x.idSubCliente == me.$route.params.idSubCliente)[0].idSubCliente;
        //             }
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //         });
        // },
        listarVendedores() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-vendedores-iqc", {
                    params: {
                        estado: 2,
                         idCliente:me.$store.state.user.uidClient
                    }
                })
                .then(function (response) {
                    me.listaVendedores = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },


        obtenerCotizacionCertificacionSucursales(idDetalleCotizacionCertificacionB) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/obtener-cotizacion-certificacion-sucursales", {
                    params: {
                        idDetalleCotizacionCertificacionB: idDetalleCotizacionCertificacionB,
                    }
                })
                .then(function (response) {
                    //console.log(response.data)
                    me.listaDocumentoEmpresaSucursal = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerCotizacionCertificacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/obtener-cotizacion-certificacion", {
                    params: {
                        idCotizacionCertificacion: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    //console.log(response.data[0])
                    me.datosCotizacion = response.data[0];
                    if (response.data[0].idDetalleCotizacionCertificacionB != null) {
                        me.obtenerCotizacionCertificacionSucursales(response.data[0].idDetalleCotizacionCertificacionB);
                    }

                    //DatosGenerales
                    me.datosCotizacion.normas = JSON.parse(response.data[0].normas);
                    me.datosCotizacion.integraciones = JSON.parse(response.data[0].integraciones);
                    me.datosCotizacion.tipoCertificaciones = JSON.parse(response.data[0].tipoCertificaciones);
                    me.datosCotizacion.tipoAuditorias = JSON.parse(response.data[0].tipoAuditorias);
                    me.datosCotizacion.tipoAcreditacion = JSON.parse(response.data[0].tipoAcreditacion);
                    me.datosCotizacion.estado = response.data[0].estado;77777
                    me.datosCotizacion.idCotizacionCertificacion = response.data[0].idCotizacionCertificacion;
                    me.datosCotizacion.idCliente = response.data[0].idCliente;
                    me.datosCotizacion.idSubCliente = response.data[0].idSubCliente;
                    //Sección 1
                    me.datosCotizacion.ubicacionesAmbito = JSON.parse(response.data[0].ubicacionesAmbito);
                    me.datosCotizacion.acreditacionesRequeridad = JSON.parse(response.data[0].acreditacionesRequeridad);
                    me.datosCotizacion.metodologiasAuditoria = JSON.parse(response.data[0].metodologiasAuditoria);
                    me.datosCotizacion.infraestructurasAuditoriaRemota = JSON.parse(response.data[0].infraestructurasAuditoriaRemota);

                    //Sección 2
                    me.datosCotizacion.detalleEmpresa = JSON.parse(response.data[0].detalleEmpresa);
                    me.datosCotizacion.listaPersonal = JSON.parse(response.data[0].listaPersonal);

                    //Sección 3
                    me.datosCotizacion.nivelesIntegracion = JSON.parse(response.data[0].nivelesIntegracion);
                    me.datosCotizacion.detallesImpactoCubren = JSON.parse(response.data[0].detallesImpactoCubren);
                    me.datosCotizacion.detallesAspectoSimilar = JSON.parse(response.data[0].detallesAspectoSimilar);
                    me.datosCotizacion.detallesAbarcaPunto = JSON.parse(response.data[0].detallesAbarcaPunto);
                    me.datosCotizacion.listaTipoOrganizacion = JSON.parse(response.data[0].listaTipoOrganizacion);
                    me.datosCotizacion.centroDatos = JSON.parse(response.data[0].centroDatos);
                    me.datosCotizacion.servidores = JSON.parse(response.data[0].servidores);
                    me.listaAplicaciones = JSON.parse(response.data[0].listaAplicaciones);
                    me.listaAplicacionesSoporte = JSON.parse(response.data[0].listaAplicacionesSoporte);

                    //Sección 4

                    me.datosCotizacion.informes = JSON.parse(response.data[0].informes);
                    me.datosCotizacion.valorFinalIGV = parseFloat(me.datosCotizacion.valorFinal) + parseFloat(me.datosCotizacion.igv);

                    !!me.datosCotizacion.normas.find(x => x == 1) ? me.disabledNorma1 = true : me.disabledNorma1 = false;
                    !!me.datosCotizacion.normas.find(x => x == 2) ? me.disabledNorma2 = true : me.disabledNorma2 = false;
                    !!me.datosCotizacion.normas.find(x => x == 3) ? me.disabledNorma3 = true : me.disabledNorma3 = false;
                    !!me.datosCotizacion.normas.find(x => x == 4) ? me.disabledNorma4 = true : me.disabledNorma4 = false;
                    !!me.datosCotizacion.normas.find(x => x == 5) ? me.disabledNorma5 = true : me.disabledNorma5 = false;
                    !!me.datosCotizacion.normas.find(x => x == 6) ? me.disabledNorma6 = true : me.disabledNorma6 = false;
                    me.checkUbicaciones();
                    me.checkAcreditaciones();

                })
                .catch(function (error) {
                    // me.swat('error', 'Algo salió mal!')
                });
        },
        registrarCotizacion() {

            let me = this;
            me.disabled = true;

            const formData = new FormData();

            let igv = (parseFloat(me.datosCotizacion.valorFinal) * 0.18).toFixed(2);
            me.datosCotizacion.igv = igv;

            formData.append("datosCotizacion", JSON.stringify(me.datosCotizacion));
            formData.append("idCliente", me.datosCotizacion.idCliente);
            formData.append("filey1", me.datosCotizacion.archivoProcesosFabricacion);
            formData.append("filey2", me.datosCotizacion.archivoProductosFabricados);
            formData.append("folder4", 'cotizaciones-certificacion/documentos-seccion-4');

            let idDoc = 0;

            for (const i in me.listaDocumentoEmpresaSucursal) {
                if (me.listaDocumentoEmpresaSucursal[i].idDocumentoSucursal == 0 && me.listaDocumentoEmpresaSucursal[i].documento != null) {
                    idDoc = idDoc - 1;
                    formData.append("file&&" + idDoc, me.listaDocumentoEmpresaSucursal[i].documento);
                } else {
                    formData.append("file&&" + me.listaDocumentoEmpresaSucursal[i].idDocumentoSucursal, me.listaDocumentoEmpresaSucursal[i].documento);
                }
            }

            formData.append("datosDocumentosSucursal", JSON.stringify(me.listaDocumentoEmpresaSucursal));
            formData.append("folder2", 'cotizaciones-certificacion/documentos-seccion-2/sucursal');
            formData.append("fileB", me.datosCotizacion.documentoOrganigrma);
            formData.append("folder22", 'cotizaciones-certificacion/documentos-seccion-2');

            formData.append("datosListaAplicaciones", JSON.stringify(me.listaAplicaciones));
            formData.append("datosListaAplicacionesSoporte", JSON.stringify(me.listaAplicacionesSoporte));
            formData.append("fileC", me.datosCotizacion.documentoEvaluacionAntisoborno);
            formData.append("folder3", 'cotizaciones-certificacion/documentos-seccion-3');
            formData.append("disabledNorma1", me.disabledNorma1);
            formData.append("disabledNorma2", me.disabledNorma2);
            formData.append("disabledNorma3", me.disabledNorma3);
            formData.append("disabledNorma4", me.disabledNorma4);
            formData.append("disabledNorma5", me.disabledNorma5);
            formData.append("disabledNorma6", me.disabledNorma6);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-cotizacion-certificacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: 'Cotizaciones certificacion'
                    })
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });

        },

        traducir(texto) {
            return translate(texto, {
                from: "es",
                to: "en",
                engine: "google",
                key: "AIzaSyBfEPrAhMQ9wHfGhGU6B_N0uB_YyKlfK94",
                cache: 0
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },

    },
    watch: {
        modalAgregarPersonal: function (val) {
            if (val == false) {
                this.resetModalAgregarPersonal();
            }
        },
    },
    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCotizacion.idCliente = user.uidClient;
            this.listarClientes();
            this.listarVendedores();
            if (this.$route.params.id) {
                this.obtenerCotizacionCertificacion();
            }
        }

    }

}
</script>

<style>
.disabledTab {
    pointer-events: none;
    /* background:rgba(37, 37, 37, 0.048); */
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
    cursor: not-allowed !important;
    background-color: #ffffff !important;
}
</style>
